
.wrapper--newsletter {
  background-color: $color-secondary;
  padding: $gutter 0;  
  margin-top: $gutter; 

  .newsletter {
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    flex-direction: column;
    @include bp('lg') {
      flex-direction: row;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      
    }
  }
  .newsletter__header{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
      // CSS pour IE versions 10, 11 et Edge
      padding-right: 25px;
    }
  }
 
  .newsletter__body {
    @include bp('lg') {
      max-width: 1000px;
    }
    #gform_confirmation_message_3 {
      color: white;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
      // CSS pour IE versions 10, 11 et Edge
      width: 100%;
    } 
  }

  .newsletter__title {  
    color: white;
    border: none;
    font-weight: initial;
    text-transform: none;
  }
  .newsletter__form {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;

    @include bp('xs') {
      #gform_wrapper_3 {
        form {
          display: flex;
          display: -webkit-box; 
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex; 
          justify-content: space-between;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
        }
        .gform_body {        
          flex-basis: 74%;
          -webkit-flex-basis: 74%;
          -moz-flex-basis: 74%; 
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
            // CSS pour IE versions 10, 11 et Edge
            flex-basis: auto;
            width: 74%;
          }         
        }
        .gform_footer {
          flex-basis: 24%;
          -webkit-flex-basis: 24%;
          -moz-flex-basis: 24%; 
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
            // CSS pour IE versions 10, 11 et Edge
            flex-basis: auto;
            width: 24%;
          }    
        }
        #field_3_1 {
          margin-top: 0;
        }
        li.gfield {
          margin-top: 0;
        }
      }
    }
    #gform_wrapper_3 .gfield_label {
      display: none;
    }
    #gform_3 {
      li.gfield.gfield_error {
        background-color: transparent !important;
        border-top: none !important;
        border-bottom: none !important;
      }
      label {
        color: white;
      }
      .gform_fields > * {
        padding: 0;
      }
      .ginput_container_email {
        margin-top: 0;
      }
    
      .gform_footer {

        padding: 0;
        margin: 0;
        text-align: left;
        
      }
      .gform_button {
        padding: 0 $gutter;
        min-height: 44px;
        border: 1px solid white;
        color: white;
        &:hover {
          border: 1px solid $color-primary;
        }
      }
    }
  }
  .newsletter__rgpd {
    color: white;
    font-size: 1.2rem;
    a {
      color: white;
      font-weight: bold;
      font-size: 1.4rem;      
    }
  }
}
