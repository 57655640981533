/* LOGO */

.navbar-logo {
  display: block;
  height: $navbar-logo-height;
  padding-left: $gutter-half;
  padding-right: $gutter-half;
}

.logo {
}

.logo--mobile {
  height: $navbar-logo-height;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    width: 125px;
  } 
  @include bp($expanded) {
    display: none;
  }
}

.logo--desktop {
  height: $navbar-logo-height-desktop;
  display: none;
  position: absolute;
  // top: -12.2rem; // When positioned with relative
  top: 5.3rem;

  @include bp($expanded) {
    display: inline-block;
  }
}
