/* SKIN - LIGHT - HP */

// Nav widget

.categories {


  .category__content {
    min-height: 180px;
    background-color: $light-bg;
    padding: 2rem 0;
    cursor: pointer;
    border-radius: $gutter-half;
    text-align: center;   
    &:hover,  &:hover a{
      color: white;

      .cls-1{
        fill: white;
        stroke: white;
      }
    }
  }
  .category__img svg{
    width: 70px;
    height: 70px;
  }

  .category--horaire {
    color: #0070BA;
    
    .category__content:hover {
      background-color: #0070BA;
    }
     
    .cls-1 {
      fill:#0070ba;
      stroke:#0070ba
    }  
  }
  .category--plan {
    color: #00BCAD;
    a {color: #00BCAD;}
   
    .category__content:hover {
      background-color: #00BCAD;
    }
    
    .cls-1 {
      fill:#00BCAD;
      stroke:#00BCAD
    }  
  }
  .category--reservation {
    color: #009A43;
    a {color: #009A43;}

    .category__content:hover {
      background-color: #009A43;
    }
     
    .cls-1 {
      fill:#009A43;
      stroke:#009A43
    }  
  }
  .category--vialsace{
    color: #0F4E78;
    a {color: #0F4E78;}
    
    .category__content:hover {
      background-color: #0F4E78;
    }
    
    .cls-1 {
      fill:#0F4E78;
      stroke:#0F4E78
    }  
  }
  
  .category__title {
    font-size: 2.5rem;
    text-transform: uppercase;
  }
  .category__body {
    font-size: 1.4rem;
  }
}

.services-hp {

  .service__content {
    padding: $gutter;
    background-color: $color-secondary;
    background-image: url('../img/sprites-css/circle-widget.svg'); 
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 30%;
    color: white;
    cursor: pointer;
    border-radius: $gutter-half;
    text-align: center;
    overflow: hidden;
    a {
      color: white;
    }
    &:hover {
      background-color: #024C7E;
    }
  }
  
  .service__title {
    font-size: 2.5rem;
    text-transform: uppercase;
  }
  .service__body {
    font-size: 1.4rem;
  }
}

.wrapper--blog { 
  padding-top: $gutter-quad;
  h2 {
    text-transform: uppercase;
  }
  .wrapper--news {
    h2 {
      color: $color-secondary;    
    }
    article{
      flex-basis: 100%;
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
      @include bp('sm') {
        flex-basis: 50%;
        -webkit-flex-basis: 50%;
        -moz-flex-basis: 50%;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
          // CSS pour IE versions 10, 11 et Edge
          flex-basis: auto;
          width: 50%;
        }
      }
    }
  
  } 
  .wrapper--trafic {
    h2 {
      color: $color-ternary;
    }
    article{
      flex-basis: 100%;
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
    }
    
  } 
  
}