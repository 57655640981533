/* DEBUG */

// Debug touchzones

.debug.debug--touchzones {
  a,
  button,
  .btn {
    outline: 1px dashed red;
  }
}
