/* LAYOUT */

// STICKY FOOTER

html {
  min-height: 100vh;
}
body,
.site-wrapper {
  min-height: 100vh;
}
body {
  overflow-x: hidden;
}
.site-wrapper {
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;
}

.site-content {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    min-height: 100vh;
  } 
}

// ARTICLE CONTENT

.article-content {
  padding-top: $gutter-double;
  padding-bottom: $gutter-double;
}

// CENTERED MOBILE FOOTER

.site-footer {
  text-align: center;

  @include bp('md') {
    text-align: left;
  }
}

// MAIN PAGE TITLE

// Homepage title is only for SEO
.main-title--hidden {
  display: none;
}

// ALIGNEMENT HELPERS

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// OVERLAY

@include bp($expanded, true) {
  body.navbar-expanded {
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 3px,
        rgba(0, 0, 0, 0.5) 3px,
        rgba(0, 0, 0, 0.5) 6px
      ),
      linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25));

    transform: translate3d(-100%, 0, 0);
    opacity: 0;
    transition: opacity 0.5s, transform 0.25s;

    &.navbar-expanded {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
