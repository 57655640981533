/* SKIN - LIGHT - WRAPPERS */

// Macro-wrappers

// .site-wrapper {         background-color: white; }
// .site-header {          background-color: white; }
// .site-content {         background-color: white; }
.site-footer {
  background-color: $gray-lightest;
  @include shadow(2);
}

// Micro-wrappers

.wrapper--pre-header {
}
.wrapper--header {
  background-color: $gray-lighter;
}
.wrapper--post-header {
}

.wrapper--pre-footer {
}
.wrapper--footer {
  padding-top: $gutter-double;
}
.wrapper--post-footer {
}

.wrapper--content {
}

// Nano-wrappers

.wrapper--hero {
}
.wrapper--article-content {
}
.wrapper--posts {
  padding-top: $gutter-double;
  padding-bottom: $gutter-double;
}

.wrapper--section {
  margin-bottom: $gutter-triple; 
}
.wrapper--breadcrumb {
  padding-top: $gutter-quarter;
  padding-bottom: $gutter-quarter;
  width: 100%;
  position: absolute;
}
.wrapper--padding {
  @include bp('md') { 
    padding-right: $gutter-double;
  }
  @include bp('lg') { 
    padding-right: $gutter-quad;
  }
}
.article {
} // TODO: wrapper for an article main content?
