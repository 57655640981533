table.table-price {
  width: 100%;
  th {
    height: 42px;
    background-color: $color-primary;
    color: white;
  }
  tbody {
    box-shadow: 0 0 0 1px #F2F4FA;
    border-radius: 5px;
    tr {
      height: 42px;
      &:nth-child(odd) {
        background-color: #F2F4FA;
      }
      td:first-child {
        font-weight: bold; 
      }
      td:not(:first-child) {
        text-align: center; 
      }
    }
  }
} 