.circle-widget {
  .cls-1 {
    fill: #fafafa;
  }

  .cls-3 {
    fill: none;
  }

  .cls-4, .cls-5 {
    stroke: none;
  }

  .cls-5 {
    fill: #009a43;
  }
}

.logo-obernai {
  .cls-1, .cls-2, .cls-3 {
    fill: #fff;
  }

  .cls-1, .cls-2, .cls-3, .cls-4 {
    stroke: #fff;
    stroke-width: 2px;
  }

  .cls-1 {
    stroke-miterlimit: 10;
  }

  .cls-3, .cls-4, .cls-6, .cls-7, .cls-8 {
    fill-rule: evenodd;
  }

  .cls-4, .cls-7 {
    fill: none;
  }

  .cls-5, .cls-8 {
    fill: #0073b6;
  }
}
.picto-contact {
  .cls-3 {
    fill: #fff;
  }
}
.picto-transport {
  .cls-1 {
    fill: #0073b6;
  }
}