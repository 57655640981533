@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    $unit    : '%';
    $mods: "xs", "sm", "md", "lg";
    // CSS pour IE versions 10, 11 et Edge
  
    @each $mod in $mods {
        
        @include bp($mod) {
        
            @for $i from 1 through 12 {
                .col-#{$mod}-#{$i} {
                    $width: 100 * $i /12;
                    flex: auto;
                    width: $width#{$unit};
                }
            }
        }
    }  
    
}