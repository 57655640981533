.gform_wrapper {
  ul.gform_fields { 
    display: flex !important;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    li.gfield.gf_half {
      flex-basis: 100%;
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
      @include bp('sm') {
        flex-basis: 50%;
        -webkit-flex-basis: 50%;
        -moz-flex-basis: 50%;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
          // CSS pour IE versions 10, 11 et Edge
          flex-basis: auto;
          width: 50%;
        } 
      }
    }
    li.gfield.gf_quart {
      flex-basis: 100%;
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
      @include bp('sm') {
        flex-basis: 25%;
        -webkit-flex-basis: 25%;
        -moz-flex-basis: 25%;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
          // CSS pour IE versions 10, 11 et Edge
          flex-basis: auto;
          width: 25%;
        } 
      }
    }
  }
  .top_label input.medium.datepicker {
    width: 100% !important;
  }
  .gfield_time_hour, .gfield_time_minute {
    width: 50% !important;
    max-width: 50% !important;
    input {
      width: 100% !important;
    }
    label, i {
      display: none !important;
    }
  }
  .gform_footer {
    text-align: center;
  }
  .gfield_checkbox {
    label {
      font-weight: 300;
    }
  }
  div.validation_error {
    border-top: 2px solid $color-error !important;
    border-bottom: 2px solid $color-error !important;
    color: $color-error !important;
  }
  .validation_message {
    color: $color-error !important;
  }
}