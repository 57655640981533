/* FOOTER */

.widget_nav_menu ul {
  list-style-type: none;
  padding-left: 0;

  > li {
    > a {
      display: inline-block;
      padding-bottom: $gutter-quarter;
      color: $gray;
      transition: all 0.25s;

      &:hover,
      &:active,
      &:focus {
        color: $gray-darkest;
      }
    }
  }
}

.footer-copyright,
.footer-made-by {
  color: white;
}

.footer-copyright {
  @include bp('sm') {

    .footer-copyright__copyright {
      text-align: left;
    }
    .footer-copyright__legal {

      text-align: right;
    }
  }
}

.maker-link {
  font-size: 0;
  opacity: 0.8;
  transition: all 0.25s;
  > svg {
    height: 2rem;
    position: relative;
    top: 0.5rem;
  }

  &:hover {
    opacity: 1;
  }
}
