/* NAVS */

.nav {
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;

  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  > * {
    flex: 1 0 100%;

    @include bp($expanded) {
      flex: 0 0 auto;
    }
  }
}
