.actor {

  margin-bottom: $gutter-quad;
  .actor__title {
    font-size: $font-size-h2;
    font-weight: bold;
    margin-bottom: $gutter;
  }
  .actor__link{
    margin-top: $gutter;
  }
}