/* BUTTONS */

.btn {
  display: inline-block;
  margin-bottom: $gutter-half; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: none;
  white-space: nowrap;
  text-transform: $btn-text-transform;
  // height: 4.4rem; // Recommanded touch size
  background-color: transparent;
  padding: 1rem $gutter-half;
  transition: all 0.25s;
  color: $text-color-base;

  &:hover {
    @include shadow(2);
    color: $text-color-base;
  }

  // TODO : focus, active, disabled

  &:disabled {
    @include shadow(0);
    opacity: 0.75;
    cursor: not-allowed;
  }
}

// BUTTON SIZES

.btn-xs {
  padding: $gutter-eighth $gutter-quarter;
  font-size: $font-size-small;
}

.btn-sm {
  padding: $gutter-eighth $gutter-half;
}

.btn-lg {
  padding: ($gutter-eighth * 3) ($gutter-quarter * 3);
  font-size: $font-size-big;
}

.btn-xl {
  padding: ($gutter-eighth * 5) $gutter;
  font-size: $font-size-big;
}

// BUTTONS COLORS

.btn-default {
  background-color: $gray-lighter;
  color: $text-color-base;

  &:hover:not(:disabled) {
    background-color: $gray-light;
    color: $text-color-base;
  }
}
.btn-primary {
  border: 1px solid $color-primary;
  color: $color-primary;
  padding: $gutter-half $gutter;

  &:hover:not(:disabled) {
    background-color: $color-primary;
    color: white;
  }
}
.btn-secondary {
  background-color: $color-secondary;
  color: white;

  &:hover:not(:disabled) {
    background-color: $color-secondary-dark;
    color: white;
  }
}
.btn-ternary {
  background-color: $color-ternary;
  color: white;

  &:hover:not(:disabled) {
    background-color: $color-ternary-dark;
    color: white;
  }
}

// HOLLOW BUTTONS

.btn-hollow {
  background-color: transparent;
  border: 0.2rem solid;

  &:hover:not(:disabled) {
    @include shadow(0);
  }

  &.btn-default {
    color: $gray-light;
    border-color: $gray-light;

    &:hover:not(:disabled) {
      background-color: $gray-light;
      color: white;
    }
  }
  &.btn-primary {
    color: $color-primary;
    border-color: $color-primary;

    &:hover:not(:disabled) {
      background-color: $color-primary;
      color: white;
    }
  }
  &.btn-secondary {
    color: $color-secondary;
    border-color: $color-secondary;

    &:hover:not(:disabled) {
      background-color: $color-secondary;
      color: white;
    }
  }
  &.btn-ternary {
    color: $color-ternary;
    border-color: $color-ternary;

    &:hover:not(:disabled) {
      background-color: $color-ternary;
      color: white;
    }
  }
}

// ROUNDED BUTTONS

.btn-round {
  border-radius: 100rem;
}

// BLOCK BUTTONS

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
// .btn-block + .btn-block {
//   margin-top: $gutter-quarter;
// }

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

// WRAP BUTTONS (multiline)

.btn-wrap {
  white-space: normal;
}
