/* HERO */

.hero {
  position: relative;
  min-height: 25vw; 
  //min-height: 56.25vw; // 16:9 ratio

  // Full height hero
  /*body*/
  .home & {
    // min-height: calc(100vh - $navbar-height-mobile);
    min-height: calc(100vh - 6rem);

    @include bp($expanded) {
      min-height: calc(100vh - 12rem);
    }
  }
  .transparent-header.home & {
    min-height: 100vh;
  }
}

.hero__img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.hero__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  width: 90%;

  @include bp('sm') {
    width: 75%;
  }

  @include bp('md') {
    width: 50%;
  }
}

.hero__slogan {
  @extend .h1;
  color: white;
  text-shadow: 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.5);
}

.hero__btn {
  @include bp('md') {
    // @extend .btn-lg;
    padding: ($gutter-eighth * 3) ($gutter-quarter * 3);
    font-size: $font-size-big;
  }
}

.hero__scroll-down {
  $scroll-down-size: $gutter-double;

  width: $scroll-down-size;
  height: $scroll-down-size;
  // !important needed to override .ripple styling
  position: absolute !important;
  bottom: $gutter-quad;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    width: $scroll-down-size * 1.25;
    height: $scroll-down-size * 1.25;
  }
}
