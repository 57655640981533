/* BASE */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $body-color-base;
  color: $text-color-base;
  margin: 0;
}

// Avoid 150px height default behaviour
.inline-svg {
  svg {
    display: none;
  }
}

// Responsive images by default
img {
  max-width: 100%;
}
