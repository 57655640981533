////////////////////////////////////////////////////////////////////////////////
// VARIABLES

// GUTTER

$gutter: 3rem;

$gutter-half: $gutter / 2;
$gutter-quarter: $gutter / 4;
$gutter-eighth: $gutter / 8;
$gutter-double: $gutter * 2;
$gutter-quad: $gutter * 4;
$gutter-octo: $gutter * 8;
// BREAKPOINTS

// List of breakpoint names and sizes

// Common screen sizes, in em unit (1em = 16px, browser default font-size)
// 'xxs':   20em,    //  320 px
// 'xs':    30em,    //  480 px
// 's':     40em,    //  640 px
// 'sm':    48em,    //  768 px
// 'md':    60em,    //  960 px
// 'ml':    64em,    // 1024 px
// 'lg':    75em,    // 1200 px
// 'xl':    90em,    // 1440 px
// 'xxl':   100em,   // 1600 px
// 'xxxl':  120em    // 1920 px

$breakpoints: (
  'xs': 30em,
  'sm': 48em,
  'md': 60em,
  'lg': 75em,
  'xl': 100em
) !default;

// The breakpoint at which starts the desktop layout
$expanded: 'md';

// COLORS

$color-primary: #009A43 !default;
$color-secondary: #0070BA !default;
$color-ternary: #FF9800 !default;

$color-variation-ratio-base: 10% !default;
$color-variation-ratio-more: 20% !default;
$color-variation-ratio-most: 30% !default;

// Computed colors
@import 'lib/colors';

$color-error: red;
$color-warning: orange;
$color-info: blue;
$color-success: green;

// Site wide

$text-color-base: $gray-darker;
$body-color-base: white;

$link-color: $color-secondary;
$link-color-active: $color-secondary-darker;

// NAVBAR CONFIGURATION

$header-color-bg: white;

$navbar-menu-mobile-width: 15em !default;

// Navbar height
$navbar-height-mobile: 6rem !default;
$navbar-height-desktop: $navbar-height-mobile * 2.5 !default;
// Topbar height
$topbar-height-mobile: 6rem !default;
$topbar-height-desktop: $topbar-height-mobile !default;

// Navbar buttons
$navbar-btn-size: 4.4rem !default;
$navbar-btn-padding: 1rem !default;
$navbar-btn-color: $gray-light !default;
$navbar-btn-color-hover: $gray-darker !default;

// WordPress admin bar
$wp-adminbar-height-mobile: 4.6rem !default;
$wp-adminbar-height-desktop: 3.2rem !default;
$wp-adminbar-bp: 782px;

// Logo
$navbar-logo-height: $navbar-btn-size !default;
$navbar-logo-height-desktop: 9rem !default;

// TYPOGRAPHY

$font-family-sans-serif: 'Roboto', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1.6rem;
$line-height-base: 1.5;
$line-height-small: 1.25;
$line-height-smaller: 1.125;

$font-size-big: (floor($font-size-base * 1.25 * 10) / 10);
$font-size-small: (floor($font-size-base * 0.9 * 10) / 10);

// Headings

$font-size-h1: 3.2rem;
$font-size-h2: 2.4rem;
$font-size-h3: 2.2rem;
$font-size-h4: 1.6rem;
$font-size-h5: $font-size-base;
$font-size-h6: (floor($font-size-base * 0.9 * 10) / 10);

$font-weight-h1: 500;
$font-weight-h2: bold;
$font-weight-h3: 500;
$font-weight-h4: bold;
$font-weight-h5: bold;
$font-weight-h6: normal;

$font-family-h1: $font-family-sans-serif;
$font-family-h2: $font-family-sans-serif;
$font-family-h3: $font-family-sans-serif;
$font-family-h4: $font-family-sans-serif;
$font-family-h5: $font-family-sans-serif;
$font-family-h6: $font-family-sans-serif;

// Border radiuses

$border-radius-base: 0;
$border-radius-large: 1.4142 * $border-radius-base;
$border-radius-small: 2 * $border-radius-base;

// Buttons

$btn-font-weight: 700;
$btn-text-transform: uppercase;

// FORMS

$input-bg: #fff;
$input-border-color: #ccc;
// $input-color-placeholder: #999;
$light-bg: #F4F4F4;
