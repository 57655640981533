/* TYPE */

// PARAGRAPHS

p {
  margin-bottom: $gutter;
}

.small {
  font-size: $font-size-small;
}

.big {
  font-size: $font-size-big;
}

blockquote {
  font-family: $font-family-serif;
  font-style: italic;
  color: $gray;
  border-color: $color-primary;
}

hr {
  margin-bottom: $gutter;
}

// LINKS

a {
  color: $link-color;
  text-decoration: none;
  transition: all 0.25s;

  &:hover,
  &:active,
  &:focus {
    color: $link-color-active;
  }
}

// HEADINGS

h1,
.h1 {
  margin-bottom: $gutter-double;
  font-size: $font-size-h1 * 3 / 4;
  line-height: $line-height-smaller;
  font-weight: $font-weight-h1;
  font-family: $font-family-h1;
  color: $color-secondary;
  text-transform: uppercase;
}
h2,
.h2 {
  font-size: $font-size-h2 * 3 / 4;
  line-height: $line-height-smaller;
  font-weight: $font-weight-h2;
  font-family: $font-family-h2;
  color: $color-primary;
  border-bottom: 2px solid;
  padding-bottom: $gutter-quarter;
  text-transform: uppercase;
}
h3,
.h3 {
  font-size: $font-size-h3 * 3 / 4;
  line-height: $line-height-small;
  font-weight: $font-weight-h3;
  font-family: $font-family-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
  line-height: $line-height-small;
  font-weight: $font-weight-h4;
  font-family: $font-family-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
  font-weight: $font-weight-h5;
  font-family: $font-family-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-h6;
  font-family: $font-family-h6;
}

@include bp('xs') {
  h1,
  .h1 {
    margin-bottom: $gutter-double;
    font-size: $font-size-h1 * 7 / 8;
  }
  h2,
  .h2 {
    font-size: $font-size-h2 * 7 / 8;
    line-height: $line-height-smaller;
  }
  h3,
  .h3 {
    font-size: $font-size-h3 * 7 / 8;
    line-height: $line-height-small;
  }
}

@include bp('sm') {
  h1,
  .h1 {
    margin-bottom: $gutter-double;
    font-size: $font-size-h1;
  }
  h2,
  .h2 {
    font-size: $font-size-h2;
    line-height: $line-height-smaller;
  }
  h3,
  .h3 {
    font-size: $font-size-h3;
    line-height: $line-height-small;
  }
}

// Reset all margin top

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
ul,
blockquote {
  margin-top: 0;
}

// MISC

// Monospace elements

pre,
code {
  font-family: $font-family-monospace;
}
