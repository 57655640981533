/* SKIN - LIGHT - FOOTER */

// Nav widget
.widget {
  .widget-title {
    color: $color-primary;
  }
}
.widget_nav_menu {
  
  ul {
    > li {
      > a {
        position: relative;
        &:after {
          position: absolute;
          left: 0;
          content: '';
          display: block;
          width: 100%;
          height: 0.2rem;
          background-color: currentColor;
          transform: scale3d(0, 1, 1);
          transition: all 0.25s $ease-bump;
        }
        &:before {
          content: '\003E \00a0';
        }
        &:hover,
        &:focus,
        &:active {
          color: currentColor;
          text-decoration: none;
          &:after {
            transform: scale3d(1, 1, 1);
          }
        }
      }
    }
  }
}
.footer__links {
  @include bp('md') {
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    flex-direction: row;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    &:nth-child(1), &:nth-child(2) {
      border-right: 1px solid #707070;
    }
  }
}
.hidden-only-md {
  @include bp('md') {
    display: none;   
  }
  @include bp('lg') {
    display: block;   
  }
}
.site-footer {
  background-image: url('../img/sprites-css/circle-widget.svg'); 
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 200px 200px;
  background-color: #F2F4FA;
}
    
.wrapper--footer .footer__content{
  background-image: url('../img/sprites-css/picto-transport.svg'); 
  background-position: 0 98%;
  background-repeat: no-repeat;
  background-size: 224px 53px;
  padding-bottom: $gutter-double;
  background-color:rgba(242, 244, 250, 0.9);
}
.wrapper--post-footer {
  background-color: $color-primary;
  .post-footer__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
   
    @include bp('sm') {
      flex-direction: row;
    }
  }
  .footer-copyright, .footer-made-by {
    padding: $gutter-half;
    
    svg {
      #Calque_1 , #Calque_2 {
        fill: white;
      }
    }
    @include bp('sm') {
      padding: 0;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    .footer-made-by {
      a {
        display: inline-block;
      }
      svg {
        width: 82px;
      }
    }
  } 
}
.wrapper--post-footer .column {
  @include bp('sm') {
    height: 55px;
  }
}

