.offer {

  margin-bottom: $gutter-double;
  padding-bottom: $gutter-double;
  border-bottom: 2px solid #E5EEFF;

  .offer__title {
    font-size: $font-size-h2;
    font-weight: bold;
    margin-bottom: $gutter;
  }
}