.ligne {
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  margin-bottom: $gutter;

  .ligne__number {
    flex-basis: 100px;
    -webkit-flex-basis: 100px;
    -moz-flex-basis: 100px;
  }
  .ligne__circle {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-h2;
   
  }
  .ligne__body {
    flex-basis: calc(100% - 100px);
    -webkit-flex-basis: calc(100% - 100px);
    -moz-flex-basis: calc(100% - 100px);
  }
  .ligne__title {
    font-size: $font-size-h2;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-top: 12px;
  }
  .ligne__download-pdf {

    margin-top: $gutter;
    
    .download-pdf__link {
      background-color: #D6D6D6;
      padding: $gutter-half;
      text-transform: uppercase;
      color: black;
      border-radius: $gutter-half;
      display: inline-block; 
   
      &:hover {
        background-color: #00BCAD;
        color: white;
      }
    }
    .fa-file-pdf {
      margin-right: $gutter;
    }
  }
}
.ligne--a .ligne__circle{
  border: 10px solid #E7C043;
}
.ligne--b .ligne__circle{
  border: 10px solid #90119F;
}
.ligne--t .ligne__circle{
  border: 10px solid #D6724B;
}
.ligne--f .ligne__circle{
  border: 10px solid #0071BA;
}
.ligne--n .ligne__circle{
  border: 10px solid #159F52;
}
