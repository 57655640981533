.article-content-btn {
  .categories {
    
    .category {
      color: $color-secondary !important;
      margin-bottom: $gutter;
      a {
        color: $color-secondary !important;
        font-weight: bold;
      }
      
      &:hover {
        background-color: $color-secondary !important;
        border-radius: $gutter-half;
        a {
          color: white !important;
        }
        .cls-1{
          fill: white;
          stroke: white;
        }

        .picto-tickets {      
          .cls-2, .cls-3 {stroke: white;}   
          .cls-3 {fill: #0070ba;}    
          .cls-4, .cls-5 {fill: white;}     
        }
        .picto-contact {
          .cls-2 {
            fill: white;
            stroke: white;
          }
        }
      }
    }
    .cls-1 {
      fill:#0070ba;
      stroke:#0070ba
    } 
  }
  .category__content {
    background-color: transparent !important;
    border: 2px solid $color-secondary;
    padding: 2rem 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
      // CSS pour IE versions 10, 11 et Edge
      width: 100%;
    } 
  }
  
  .picto-tickets {
    .cls-2, .cls-7 {fill: none;}

    .cls-2, .cls-3 {
      stroke: #0070ba;
      stroke-width: 2px;
    }

    .cls-3 {fill: #fafafa;}

    .cls-4, .cls-5 {fill: #0070ba;}

    .cls-4 {
      font-size: 2px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
    }

    .cls-6 {stroke: none;}
  }
}
.picto-contact {
  .cls-2 {
    fill: #0070ba;
    stroke: #0070ba;
  }
}
