.serious-slider-8-rnd3233 {
  max-width: initial !important;
}
// Remove effect
.seriousslider-vflip .seriousslider-inner {
  -webkit-transform-style: flat !important; 
  transform-style: flat !important;
}
.serious-slider-8-rnd6403 .seriousslider-inner > .item {
  -webkit-transition-duration: 0s!important;
  -o-transition-duration: 0s!important;
  transition-duration: 0s!important;
}
.seriousslider .seriousslider-caption {
  -webkit-animation: 0s 0s animation-fadeIn ease forwards !important;
  animation: 0s 0s animation-fadeIn ease forwards  !important;
}
.seriousslider-light {
  .seriousslider-caption-title {
    font-size: 2.8rem !important;
    font-weight: bold;
    @include bp('xl') {
      font-size: 4.8rem !important;
      font-weight: initial;
    }
  }
  .seriousslider-caption-text {
    font-size: 1.8rem !important; 
    @include bp('xl') { 
      font-size: 2.4rem !important;
    }
  }
}
.wrapper--hero {
  .seriousslider-caption {
    position: absolute;
    right: 0 !important; 
    left: 0;
    top: 0;
    bottom: 0;  
    text-align: left; 
    pointer-events: none;
    transform: translateY(0);
  }
  .seriousslider-caption-inside {
    width: 100%;
    background-image: url(../img/sprites-css/circle-widget-white.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-color: $color-primary;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-left: calc(2% + 50px);
    padding-right: calc(2% + 50px);
    padding-top: $gutter;
    text-align: center;
    @include bp('md') {
      width: 51%;
      background-size: 100px 100px;
      padding-left: calc(2% + 100px);
      padding-right: 0;
      text-align: left;
    }
    @include bp('lg') {
      background-size: 200px 200px;
      padding-left: calc(2% + 200px);
      padding-top: $gutter-double;
     
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -95px;
      width: 0;
      height: 0;
      border-right: 100px solid transparent;
      border-top: 700px solid $color-primary;

    }

  }
  .seriousslider-caption-title {
    font-size: $font-size-h2;
    line-height: 1.2;
  }
  
  .seriousslider-light .seriousslider-caption-buttons  {
    
    a { 
      border: 2px solid #FFF;
      border-radius: initial; 
      background-color: $color-primary;     
    }
    a:nth-child(2n+1) {
      background-color: transparent; 
      color: white;

    }
    a:hover:nth-child(2n+1) {
      background-color: white;
      border-color: white;
      color: $color-primary;
    }

  }
  .seriousslider-inner > .item img.item-image {
    left: 75%;
    max-width: 100%;
    min-height: 450px;
    max-height: 670px;
    @include bp('md') {
      min-height: initial;
      max-width: calc(100% + 1px);
    }
    @include bp('lg') {

      max-width: calc(80% + 1px);
    }
  }
}