/* MENU */

$caret-size: $font-size-base / 3;
$caret-color: $text-color-base;

/////////////////////////////////////////////////////////////////////////////
// COMMON STYLES

.menu-primary,
.menu-secondary {
  .menu-item {
    > .menu-item-link {
      // Debug
      // background: $gray-lighter;
      color: $gray-darker;
      text-transform: uppercase;
      font-weight: 500;
      transition: all 0.25s;

      &:hover,
      &:active,
      &:focus {
        color: $gray-darkest;
        background: rgba(0, 0, 0, 0.125);
      }

      display: block;
      min-height: 4.4rem;
      padding-left: $gutter-half;
      padding-right: $gutter-half;
      cursor: pointer;

      // Flex vertical center
      display: flex;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      align-items: center;
      justify-content: space-between; // For carets on mobile
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;

      // don't catch submenus carret span
      > span:not(.caret) {
        line-height: 1; // In case of multi-line
        padding: $gutter-quarter 0;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
          // CSS pour IE versions 10, 11 et Edge
          line-height: 2;
        }
      }

      > .caret {
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $caret-size $caret-size 0 $caret-size;
        border-color: $caret-color transparent transparent transparent;
        transition: all 0.5s $ease-bump;
      }
    }

    
    // Active item
    &.current-menu-item {
      > .menu-item-link {
        box-shadow: -$gutter-quarter 0 0 0 $color-primary;

        @include bp($expanded) {
          box-shadow: 0 $gutter-quarter 0 0 $color-primary;
        }
      }
    }

    > .dropdown-menu {
      // reset lists
      list-style-type: none;
      padding-left: 0;

      .menu-item {
        > .menu-item-link {
          @include bp($expanded, true) {
            padding-left: $gutter;
          }
        }
      }
    }

    &.active {
      > .menu-item-link {
        > .caret {
          transform: rotateZ(180deg);
        }
      }
    }
  }
  .menu-item-34 {
    background-color: $color-primary;
    @include bp('md') {
      margin-left: $gutter-half !important;
      margin-right: $gutter-half;
    }

    &:hover {
      .menu-item-link {    
        box-shadow: 0 0 0 $gutter-quarter #1a8639 !important;
      }
    }
    
    > .menu-item-link {
      color: white!important;
      box-shadow: 0 0 0 $gutter-quarter $color-primary !important;

      &:hover,
      &:active,
      &:focus {
        color: white;
        background: #1a8639;
      }
    }
    
  }
}

// Home item
.menu-primary > .menu-item:first-child {
  .menu-item-link {
    > span {
      font-size: 0;
    }

    &:before {
      font-family: "FontAwesome";
      content: "\f015";
      display: block;
      font-size: $font-size-h2;
      color: $color-primary;
    }
  }
}


/////////////////////////////////////////////////////////////////////////////
// MOBILE MENU

// Pain in the ass to reset mobile navbar styles on larger screens
// So for once, we allow MOBILE ONLY styles max-width @mq here!
@include bp($expanded, true) {
  //No secondary menu on mobile (hidden and merged into primary one)
  .menu-primary {
    padding-top: $gutter-half;
    padding-left: $gutter-quarter;
    padding-right: $gutter-quarter;

    .menu-item:not(.dropdown),
    .dropdown-toggle {
      // Space between items when stacked
      margin-bottom: $gutter-quarter;
    }
  }
  // Hide and show sub-menus
  .dropdown-menu {
    display: none;

    .dropdown.active & {
      display: block;
    }
  }
}

/////////////////////////////////////////////////////////////////////////////
//DESKTOP MENU

@include bp($expanded) {
  .wrapper--header {
    margin-top: $navbar-height-mobile * 1.5;
    transition: all 0.25s $ease-bump;
  }

  .menu-primary,
  .menu-secondary {
    .menu-item {
      margin-left: $gutter-quarter;
      > .menu-item-link {
        > .caret {
          margin-left: $gutter-quarter;
        }
      }
    }

    // Hide and show dropdown submenu
    .dropdown {
      position: relative;
      transition: all 0.25s ease;

      .dropdown-menu {
        background-color: $header-color-bg;
        position: absolute;
        left: 0;
        width: 20vw;
        padding-top: $gutter-quarter;

        display: flex;
        flex-direction: column;
        justify-content: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        box-shadow: none;
        @include shadow(1);
        transform: scale3d(1, 0, 1);
        transform-origin: 50% 0 0;
        transition: transform 0.5s $ease-bump;
        overflow: hidden;

        > .menu-item {
          margin: $gutter-quarter;
          margin-top: 0;
        }
      }
    }

    .dropdown.active {
      @include shadow(1);
      margin-bottom: 0;

      .dropdown-menu {
        transform: scale3d(1, 1, 1);
      }
    }
  }

  .menu-primary {
    margin-right: -$gutter-half;
    margin-left: $gutter-half;

    > .menu-item {

      .menu-item-link {
  
        @include bp('lg') {
          padding-left: $gutter;
          padding-right: $gutter;
        }
  
        @include bp('xl') {
          padding-left: $gutter-double;
          padding-right: $gutter-double;
        }
      }
    }
    .menu-item-34 {
      .menu-item-link {
  
        @include bp('lg') {
          padding-left: 2.2rem;
          padding-right: 2.2rem;
        }
  
        @include bp('xl') {
          padding-left: 5.2rem;
          padding-right: 5.2rem;
        }
      }
    }

  }

  .menu-secondary {
    .menu-item {
      margin-right: $gutter;
      margin-left: 0;
    }
  }
}
