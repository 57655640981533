.vehicule {

  margin-bottom: $gutter-double;
  padding-bottom: $gutter-double;
  border-bottom: 2px solid #E5EEFF;

  .vehicule__img img{
    max-width: 350px;

  }
  .vehicule__title {
    font-size: $font-size-h2;
    font-weight: bold;
    margin-bottom: $gutter;
  }
}