.actifs-abonnement {
  .actifs-abonnement__img {
    @include bp('sm') {
      flex-basis: 350px;
      -webkit-flex-basis: 350px;
      -moz-flex-basis: 350px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
        // CSS pour IE versions 10, 11 et Edge
        flex: auto;
        width: 350px;
      } 
    }

  }
  .actifs-abonnement__body {   
    padding-top: $gutter;
    @include bp('sm') {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
        // CSS pour IE versions 10, 11 et Edge
        flex: auto;
        width: 100%;
      } 
      flex-basis: calc( 100% - 350px);
      -webkit-flex-basis: calc( 100% - 350px);
      -moz-flex-basis: calc( 100% - 350px);
    }
  }
}