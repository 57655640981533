.tickets {
  border-bottom: 2px solid #E5EEFF;
  margin: $gutter-double 0;
  padding-bottom: $gutter;

  .ticket {
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    padding-bottom: $gutter-half;
    .ticket__title {
      font-size: $font-size-base;
      @include bp('sm') { 
        font-size: $font-size-h2;
      }
    }
  }
}
.ticket__price {
  width: 80px;
  background-color: #D6D6D6;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  text-align: center;
  min-height: 36px;
}

.tickets__detail {
  border-bottom: 2px solid #E5EEFF;
  margin-bottom: $gutter-double;

  .ticket {
    margin-bottom: $gutter-double;
  }
  .ticket__headline  {
    min-height: 125px;
    padding: $gutter 2rem;
    text-align: right;
    color: white;
    font-size: $font-size-h1;
    font-weight: bold;
    line-height: 1;
    border-radius: 20px;
  }
  .ticket__title {
    font-size: $font-size-h2;
    font-weight: bold;
    padding-bottom: 1rem;
  }

  .ticket__detail {
    padding-bottom: 1rem;
    .fa-check-circle {
      color: $color-ternary;
    }
  }
  .ticket__price-info {
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  .ticket__price {
    margin-right: 2rem;
    margin-top: 1rem;
  }
 .ticket__cta {
    background-color: #00BCAD;
    min-width: 235px;
    color: white;
    border-radius: 5px;
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    text-align: center;
    min-height: 36px;
    margin-top: 1rem;
 }
  .ticket--blue {
    .ticket__headline {
      background-color: #007AB9;
    }
    .ticket__title {
      color: #007AB9;
    }
  }
  .ticket--yellow {
    .ticket__headline {
      background-color: #FDB814;
    }
    .ticket__title {
      color: #FDB814;
    }
  }
  .ticket--green {
    .ticket__headline {
      background-color: #00A653;
    }
    .ticket__title {
      color: #00A653;
    }
  }
  .ticket--pink {
    .ticket__headline {
      background-color: #ED989A;
    }
    .ticket__title {
      color: #ED989A;
    }
  }
  .ticket--orange {
    .ticket__headline {
      background-color: #F16F22;
    }
    .ticket__title {
      color: #F16F22;
    }
  }
  .ticket--greenlight {
    .ticket__headline {
      background-color: #8DC53F;
    }
    .ticket__title {
      color: #8DC53F;
    }
  }

  
}