//Page 
#post-370 .tiles.row {
  justify-content: center; 
  -webkit-justify-content: center;
  -moz-justify-content: center;
}
.tile-page {
  overflow: hidden;
}
.tile-page__content {
  min-height: 315px;
  max-width: 400px;
  height: 100%;
  border: 0.2rem solid $gray-lighter;
  padding: $gutter;
  border-radius: $gutter-half;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  box-shadow: 3px 3px 20px 0 rgba(21, 101, 192, 0.5);
  overflow: hidden;
  color: $color-secondary;
  &:hover {
    box-shadow: 6px 6px 20px 0 rgba(21, 101, 192, 0.5); 
  }
  @include bp('sm') {
    max-width: initial;
  } 
}
.tile-page__title a {
  text-transform: uppercase;
  font-weight: bold;
}
.tile-page__title a{
  font-size: 2.6rem;
}
.tile-page__body  {
  font-size: 2rem;
}
.tile-page--passo .tile-page__img,
.tile-page--localisation .tile-page__img {
  background-image: url('../img/visu_1.jpg');   
}

.tile-page--passoplus .tile-page__img,
.tile-page--contact .tile-page__img,
.tile-page--horaire-ligne .tile-page__img,
.tile-page--offers .tile-page__img {
  background-image: url('../img/visu_2.jpg');    
}

.tile-page--tempo .tile-page__img,
.tile-page--horaire-arrets .tile-page__img,
.tile-page--incident .tile-page__img,
.tile-page--actors .tile-page__img {
  background-image: url('../img/visu_3.jpg');    
}
.tile-page--flexo .tile-page__img,
.tile-page--reservation .tile-page__img {
  background-image: url('../img/visu_4.jpg');    
}
.tile-page--marche .tile-page__img{
  background-image: url('../img/visu_5.jpg');    
}
.tile-page--other .tile-page__img{
  background-image: url('../img/visu_6.jpg');    
}
.tile-page--vehicules .tile-page__img{
  background-image: url('../img/visu_7.svg');    
}

.tile-page--yelow .tile-page__img{
  border: 16px solid #FF9800;    
}
.tile-page--lightgreen .tile-page__img{
  border: 16px solid #63A941;    
}
.tile-page--lightred .tile-page__img{
  border: 16px solid #D6724B;  
}
.tile-page--blue .tile-page__img{
  border: 16px solid #0070BA;    
}
.tile-page--green .tile-page__img{
  border: 16px solid #009A43; 
}
.tile-page--red .tile-page__img{
  border: 16px solid #E73E12; 
}
.tile-page__img {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  width: 200px;
  height: 200px;
  border-radius: 100%;
  transform: translate(-35%,-35%); 
  
  .tile-page__overlay {
    width: 100%;
    height: 450px;
    background-image: radial-gradient(circle at top right, rgba(21,101,192,0.3), white);
  }
}
.tile-page__body {
    margin-top: -50px;  
}
.trafic--latest {
  height: calc(100% - 25px);
}
