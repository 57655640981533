/* NAVBAR */

///////////////////////////////////////////////////////////////////////////////
// BAR WRAPPERS

.bar-wrapper {
  // Bar content gutter
  padding-top: ($navbar-height-mobile - $navbar-btn-size) / 2;
  padding-bottom: ($navbar-height-mobile - $navbar-btn-size) / 2;

  // Gives flexbox behavious to bars
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;

  .topbar & {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
  }
}

///////////////////////////////////////////////////////////////////////////////
// TOPBAR

.topbar {
  overflow: hidden;
  height: $topbar-height-mobile;

  .topbar-menu {
  }

  .topbar-search {
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;

    @include bp($expanded) {
      flex-basis: auto;
      -webkit-flex-basis: auto;
      -moz-flex-basis: auto;
      margin-left: $gutter-half;
    }
  }
}

// Topbar on mobile - toggles visibility
@include bp($expanded, true) {
  .site-header {
    transform: translate3d(0, -$topbar-height-mobile, 0);
    transition: transform 0.5s $ease-bump;

    /*html*/
    .topbar-expanded & {
      transform: translate3d(0, 0, 0);
    }
  }
}

.menu-item--phone {
  > .menu-item-link {
    background-color: $color-ternary;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-ternary-dark !important;
    }

    > span {
      color: white;
      font-weight: bold;
    }
  }
}

.menu-item--facebook {
  > .menu-item-link {
    background-color: #1565C0;
    min-width: 4.4rem;
    text-align: center;

    &:hover,
    &:active,
    &:focus {
      background-color: darken(#1565C0, 10%) !important;
    }

    > .fab {
      color: white;
      font-size: 2rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
        // CSS pour IE versions 10, 11 et Edge
        line-height: 2;
      } 
    }

    > span {
      color: white;
      font-size: 0;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// NAVBAR HEADER

.navbar-header {
  margin-left: -$gutter-half;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
}

@include bp($expanded) {
  .container:not(.container--fluid) {
    .navbar-menu {
      margin-right: -$gutter-half;
    }
    .topbar-search {
      margin-right: $gutter;
    }
    .topbar-menu {
      margin-right: -($gutter + $gutter-half);
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// NAVBAR FOOTER

.navbar-footer {
  margin-left: $gutter-half;
  display: flex;
}

// MOBILE MENU

// Pain in the ass to reset mobile navbar styles on larger screens
// So for once, we allow MOBILE ONLY styles max-width @mq here!
@include bp($expanded, true) {
  // Mobile menu panel
  .navbar-menu {
    display: block !important;
    position: fixed;
    right: 0;
    top: 0;
    width: $navbar-menu-mobile-width * 2;
    padding-right: $navbar-menu-mobile-width;
    height: 100vh;
    padding-top: $navbar-height-mobile;
    transform: translate3d(100%, $navbar-height-mobile, 0);
    transition: transform 0.5s $ease-bump, box-shadow 0.5s;
    background-color: $header-color-bg; // TODO remove or smarter color

    &.navbar-expanded {
      // Move the navbar into viewport
      transform: translate3d(50%, $topbar-height-mobile, 0);
      @include shadow(5);
    }

    // Generated label
    &:before {
      content: 'MENU';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: $gutter-quarter * 3;
      line-height: $navbar-height-mobile;
      font-weight: bold;
      color: $gray-light;
      @include shadow(1);
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
        // CSS pour IE versions 10, 11 et Edge
        display: none;
      }
    }
  }

  .menu-primary {
    // Allow mobile menu scroll
    max-height: calc(100vh - 6rem);
    overflow: auto;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
  // CSS pour IE versions 10, 11 et Edge
 .picto-transport {
   max-width: 189px; 
   width: 100%;
 }
 .logo--desktop {
  max-width: 217px; 
  width: 100%;
 }
} 
