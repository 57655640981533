/* SKIN - LIGHT - POSTS */

///////////////////////////////////////////////////////////////////////////////
// METAS

.terms {
  line-height: 2;
  padding-bottom: $gutter-quarter;
  border-bottom: 1px dotted;

  > .btn {
    margin-bottom: 0;
  }
}

.publish {
  font-style: italic;
  color: $gray-dark;
  margin-top: $gutter-quarter;
  padding-top: $gutter-eighth;
}

.tag-link {
  text-transform: none;
  background-color: transparent;
  border-color: transparent;
  color: $color-secondary;

  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: lighten($color-secondary, 20%);
  }
}

///////////////////////////////////////////////////////////////////////////////
// POST(S)

// SINGLE

.article-content {
  position: relative;

  .metas {
    margin-bottom: $gutter;
  }
}

.article-content-body {
  // TODO : didn't find how to make a real excerpt in WP
  // the <!-- more --> thing changes nothing in markup
  // So we made the very first paragraph of content highlighted!
  > p:first-child {
    font-size: $font-size-big;
  }
}

// Move the page title over the hero image
.hero-title {
  position: absolute;
  top: 0;
  left: 0;
  // 99% to avoid black bottom line (pixel rounding issue probably)
  transform: translate3d(0, -99%, 0);
  margin: 0;
  padding: $gutter-quarter $gutter-half;
  background-color: white;

  @include bp($expanded) {
    padding: $gutter-half;
    left: -$gutter-half;
  }
}

///////////////////////////////////////////////////////////////////////////////
// TILES

.tile {
  // margin-bottom: $gutter;
}

.tile__content {
  height: 100%;
  padding: $gutter-half;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;

  > * {
    flex: 0 0 auto;

    &.tile__body {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
    }
  }

  @include shadow(1);
  transition: box-shadow 0.125s;
  cursor: pointer;

  &:hover {
    @include shadow(2);
  }
}

.tile__img {
  position: relative;
  margin: -$gutter-half;
  margin-bottom: $gutter-half;
  padding-bottom: 56.25%;
  overflow: hidden;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1);
    transition: transform 0.5s $ease-bump;

    .tile:hover & {
      transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
    }
  }
}

.tile__title {

  a {
    color: $gray-dark;
    font-weight: 600;
  }
}

.tile__body {
  margin-top: $gutter-half;
  color: $gray-light;

  .read-more {
    display: block;
    text-align: right;
  }
}

.tile__foot {
  text-align: right;

  .read-more {
    font-weight: 900;
    text-transform: uppercase;
  }
}
