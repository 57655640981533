.navbar .picto-transport {
  position: absolute;
  height: 4.5rem;
  top: 9.8rem;
  transform: translateX(250px);
  display: none;
  @include bp('md') {
    display: block;
  }

}
.header-compressed:not(.header-uncompressed) {
  .navbar .picto-transport {
    height: 0rem;
    top: 6.8rem;
  }
}
.sticky-header.compressible-header .picto-transport {
  transition: all 0.25s cubic-bezier(0.985, -0.33, 0.01, 1.32);
}
.logo-obernai {
  width: 150px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    height: 42px;
  } 
}
.picto-transport {
  .cls1 {
    fill: #0070ba;
  }
}