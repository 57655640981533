/* BREADCRUMB */

.breadcrumb {
  a {
    color: black; 
    &:hover {
      font-weight: bold;
    }
  }
  > span {
    > span {
      [property='v:title'],
      .breadcrumb_last {
        display: inline-block;
        padding: $gutter-quarter 0;
      }
    }
  }
}
