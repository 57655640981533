/* HEADER COMPRESS */

.topbar-toggle--compress {
  // Hide the "+" button by default
  display: none;
}

/*html*/
.sticky-header.compressible-header {
  // Add transitiotns to modified elements
  .site-header {
    transition: top 0.25s $ease-bump, background-color 0.5s,
      transform 0.25s $ease-bump;
  }
  .logo--desktop {
    transition: all 0.25s $ease-bump;
  }

  @include bp($expanded) {
    &.header-compressed:not(.header-uncompressed) {
      .wrapper--header {
        margin-top: 0;
      }
      .site-header {
        // Hide the topbar above the viewport
        top: -$topbar-height-desktop;
      }
      .logo--desktop {
        // Use mobile logo height
        height: $navbar-logo-height;
        // Move it back to bottom of the navbar
        // top: 0; // When positioned relative
        top: 6.8rem;
      }
      .topbar-toggle--compress {
        // Display the "+" button when compressed
        display: initial;
      }
    }

    // Wordpress admin bar
    &.admin-bar {
      &.header-compressed:not(.header-uncompressed) {
        .site-header {
          @media screen and (min-width: $wp-adminbar-bp) {
            top: $wp-adminbar-height-desktop - $topbar-height-desktop;
          }
        }
      }
    }
  }

  &.transparent-header {
    .site-header {
      @include shadow(0);
      background-color: transparent;
    }

    &.header-compressed {
      .site-header {
        background-color: $header-color-bg;
        @include shadow(1);
      }
    }

    .site-content {
      padding-top: 0;

      @include bp($expanded) {
        padding-top: 0;
      }
    }

    // TEMPORARY HACKS!

    .wrapper--breadcrumb {
      // TODO: find a solution!
      // Hide breadcrumbs
      display: none;
    }

    .main-title:not(.hero-title) {
      margin-top: $navbar-height-mobile;

      @include bp($expanded) {
        margin-top: $navbar-height-desktop + $topbar-height-desktop;
      }
    }
  }
}
