/* APP */
.column {
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;
}
.column--center {
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
}

.article__header {
  font-size: $font-size-h2;
  font-weight: bold;
  margin-bottom: $gutter;
}
.wrapper--article-title {
  background-image: url('../img/page-header.png'); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 236px;
  .article-title {
    display: flex;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    text-align: center;
    height: 100%;
    //background-color: rgba(255,255,255,0.5);   
  }
  h1 {
    margin: 0;
    color: white; 
  }
}
.info {
  border-radius: 20px;
  background-color: #F2F4FA;
  padding: 2rem;
  .info__title {
    font-weight: bold;
    font-size: $font-size-h2;
  }
  ul {
    padding-left: 10px;
  }
}
.fa-phone {
  transform: rotate(90deg);
  margin-right: 1rem;
}

.tile-page {

  p:empty {
    display: none;
  }
}