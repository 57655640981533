.service__info {
  font-weight: bold;
}
.service:nth-child(1) .service__title {
  color: $color-primary;
}
.service:nth-child(2) .service__title {
  color: $color-secondary;
}
.service:nth-child(3) .service__title {
  color: $color-ternary;
}

.agency__horaire {
  max-width: 460px;
  background-color: #D6D6D6;
  padding: $gutter;
  font-weight: bold;
  p {
    margin-bottom: 0;
  }
}
.article-content-body .article-content-btn {
  max-width: 250px;

}
.page-id-102 {

  .article-content-body .category--contact {

    .category__img {
      height: 70px;
      background-image: url('../img/sprites-css/picto-contact.svg');
      margin: $gutter 0 $gutter-half 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70px 70px;
  
    }
    &:hover {
      .category__img {
        background-image: url('../img/sprites-css/picto-contact-white.svg');    
      }
    }
  }
}